// src/utils/dateUtils.js
export const getWeekNumber = (date) => {
  const tempDate = new Date(date.getTime());
  // Set to nearest Thursday: current date + 3 - current day number (getDay())
  // Make Sunday's day number 7
  tempDate.setDate(tempDate.getDate() + 3 - ((tempDate.getDay() + 6) % 7));
  // January 4 is always in week 1
  const week1 = new Date(tempDate.getFullYear(), 0, 4);
  // Calculate full weeks to nearest Thursday
  return (
    1 +
    Math.round(
      ((tempDate - week1) / 86400000 - 3 + ((week1.getDay() + 6) % 7)) / 7
    )
  );
};

export function convertDateToWords(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();

  // Helper function to add ordinal suffix
  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return day + 'th'; // For numbers 11–20
    switch (day % 10) {
      case 1:
        return day + 'st';
      case 2:
        return day + 'nd';
      case 3:
        return day + 'rd';
      default:
        return day + 'th';
    }
  }

  const dayWithSuffix = getOrdinalSuffix(day);

  return `${dayWithSuffix} ${month} ${year}`;
}

// Example usage:
const dateInWords = convertDateToWords('2024-10-06');
console.log(dateInWords); // Output: "6th October 2024"
