import React, { useEffect, useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import { BASE_URL } from '../../Constants';
import PermissionDenied from "../Permission/PermissionDenied";
import ProgressLoader from "../ProgressLoader/ProgressLoader";

const SwaggerAccess = () => {
    const [accessGranted, setAccessGranted] = useState(false);
    const [swaggerSpec, setSwaggerSpec] = useState(null);
    const [error, setError] = useState(false); // New state for error


    useEffect(() => {
        const permissions = localStorage.getItem("permissions") || "";
        const swaggerUrl = `${BASE_URL}/api-docs`;

        fetch(swaggerUrl, {
            method: 'GET',
            headers: {
                'x-user-permissions': permissions,
            },
        })
        .then(response => {
            if (response.ok) {
                return response.json();
            } else {
                throw new Error('Access denied');
            }
        })
        .then(data => {
            setSwaggerSpec(data);
            setAccessGranted(true);
        })
        .catch(error => {
            console.error('Error accessing Swagger :', error);
            setError(true); // Set error state on failure
        });
    }, []);

    return (
        <div>
            {error ? (
                <PermissionDenied/>
            ) : accessGranted && swaggerSpec ? (
                <SwaggerUI spec={swaggerSpec} />
            ) : (
                <ProgressLoader/>
            )}
        </div>
    );
};

export default SwaggerAccess;
