import { PERMISSIONS } from '../components/PermissionsConstant';

export const getShortNameForProjects = (longName) => {
  switch (longName) {
    case '00-Holiday':
      return 'HOL';
    case '01-Leave':
      return 'LV';
    case '02-GPTW / TownHall':
      return 'GPTWTH';
    case '03-Interview':
      return 'INTVW';
    case 'ABA-IndieCommerce':
      return 'ABA-IC';
    case 'Academy':
      return 'ACD';
    case 'andKind':
      return 'ANDK';
    case 'AT&T-OFC':
      return 'ATT-OFC';
    case 'AUDD':
      return 'AUDD';
    case 'Broadland Solutions':
      return 'BRDLND';
    case 'CaptuRisk':
      return 'CAPRISK';
    case 'CFO':
      return 'CFO';
    case 'Corsaire':
      return 'CRSR';
    case 'DAC':
      return 'DAC';
    case 'Fleet Studio (Operations, Operations related Research, Leadership meetings)':
      return 'FS-OPS';
    case 'Fleet Studio - Website':
      return 'FS-WEB';
    case 'Fleet Studio Accounting (ONLY GST, PF, Payroll)':
      return 'FS-ACCT';
    case 'FleetStudio Administration (Vendor Management/ Office Maintenance/ Hardware Management)':
      return 'FS-ADMIN';
    case 'FS Apostrophe':
      return 'FS-APO';
    case 'FS Auth (FS Garage)':
      return 'FS-AUTH';
    case 'FS Campus Tool':
      return 'FS-CT';
    case 'FS CMS':
      return 'FS-CMS';
    case 'FS Connect':
      return 'FS-CONN';
    case 'FS Labs':
      return 'FS-LABS';
    case 'FS Xper':
      return 'FS-XPER';
    case 'Grindstone Advisors':
      return 'GSA';
    case 'HR (GPTW, Interviews, Town Hall, HR, Recruitment)':
      return 'HR';
    case 'Intern Training':
      return 'INT-TRNG';
    case 'Learning':
      return 'LRN';
    case 'LIT Fitness':
      return 'LIT-FIT';
    case 'Marketing':
      return 'MKT';
    case 'Medo Health':
      return 'MEDO';
    case 'Office of the CEO':
      return 'CEO';
    case 'Party Time':
      return 'PTY-TM';
    case 'PPL Events':
      return 'PPL-EVT';
    case 'Project X':
      return 'PRJX';
    case 'QAAS':
      return 'QAAS';
    case 'Sitetracker':
      return 'SITRCKR';
    case 'Thirstie':
      return 'THRST';
    case 'TIBA':
      return 'TIBA';
    case 'Training':
      return 'TRNG';
    case 'uCom':
      return 'UCOM';
    case 'Wally':
      return 'WLY';
    case 'Wally PSO':
      return 'WLY-PSO';
    case 'WhatsUp':
      return 'WUP';
    default:
      return 'Unknown Project';
  }
};

export const getShortNameForTask = (longName) => {
  switch (longName) {
    case '00-Holiday/Leave (Use this if you chose Holiday or Leave as project)':
      return 'HL-LV';
    case 'Attending/Conducting Training':
      return 'TRNG';
    case 'Content Marketing':
      return 'CNT-MKT';
    case 'Data Warehouse':
      return 'DW';
    case 'Development':
      return 'DEV';
    case 'Documentation':
      return 'DOC';
    case 'Email Marketing':
      return 'EML-MKT';
    case 'GPTW':
      return 'GPTW';
    case 'Infrastructure':
      return 'INFRA';
    case 'Interview':
      return 'INTVW';
    case 'Knowledge Transfer':
      return 'KT';
    case 'Lead Generation':
      return 'LD-GEN';
    case 'Management & Coordination':
      return 'MGMT-COORD';
    case 'Meeting (General - any meeting NOT related to the current project(s))':
      return 'MTG-GEN';
    case 'Meeting (Project - any meeting client or internal related to the current project(s))':
      return 'MTG-PROJ';
    case 'Non-billable (Idle time if there are no assigned project task also not worked on any other relevant tasks like learning etc.,)':
      return 'NON-BILL';
    case 'Product Management':
      return 'PRD-MGMT';
    case 'Product Marketing':
      return 'PRD-MKT';
    case 'Product/Project Learning (learning a new feature or learning the product/project when newly onboarded)':
      return 'PRD-LRN';
    case 'Project Management':
      return 'PRJ-MGMT';
    case 'QA - Testing':
      return 'QA';
    case 'R & D':
      return 'RD';
    case 'Recruiting / Followup':
      return 'REC-FU';
    case 'Sales':
      return 'SALES';
    case 'Social Media':
      return 'SOC-MEDIA';
    case 'Support':
      return 'SUP';
    case 'Tech Help':
      return 'TECH-HELP';
    default:
      return 'Unknown Project';
  }
};
export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // Enables smooth scrolling
  });
}

export const subtractDays = (date, days) => {
  const resultDate = new Date(date); // Create a new date object to avoid mutating the original date
  resultDate.setDate(resultDate.getDate() - days); // Subtract the specified number of days
  return resultDate.toISOString().split('T')[0]; // Return the date in YYYY-MM-DD format
};

export const isDateLess = (weekEndOf, currentDate) => {
  const endDate = new Date(weekEndOf); // Convert input to a Date object
  return endDate < new Date(currentDate); // Returns true if given date is before the current date
};

export const addDays = (date, days) => {
  const resultDate = new Date(date); // Create a new date object to avoid mutating the original date
  resultDate.setDate(resultDate.getDate() + days); // Subtract the specified number of days
  return resultDate.toISOString().split('T')[0]; // Return the date in YYYY-MM-DD format
};

export const getLastSunday = () => {
  const today = new Date();
  const lastSunday = new Date(today.setDate(today.getDate() - today.getDay()));
  return lastSunday.toISOString().split('T')[0]; // Returns date in YYYY-MM-DD format
};

export const taskArray = [
  {
    shortName: 'Holiday/Leave',
    longName:
      '00-Holiday/Leave (Use this if you chose Holiday or Leave as project)',
  },
  {
    shortName: 'Training',
    longName: 'Attending/Conducting Training',
  },
  {
    shortName: 'ContentMarketing',
    longName: 'Content Marketing',
  },
  {
    shortName: 'DataWarehouse',
    longName: 'Data Warehouse',
  },
  {
    shortName: 'Development',
    longName: 'Development',
  },
  {
    shortName: 'Documentation',
    longName: 'Documentation',
  },
  {
    shortName: 'EmailMarketing',
    longName: 'Email Marketing',
  },
  {
    shortName: 'GPTW',
    longName: 'GPTW',
  },
  {
    shortName: 'Infrastructure',
    longName: 'Infrastructure',
  },
  {
    shortName: 'Interview',
    longName: 'Interview',
  },
  {
    shortName: 'KnowledgeTransfer',
    longName: 'Knowledge Transfer',
  },
  {
    shortName: 'LeadGeneration',
    longName: 'Lead Generation',
  },
  {
    shortName: 'ManagementCoordination',
    longName: 'Management & Coordination',
  },
  {
    shortName: 'MeetingGeneral',
    longName:
      'Meeting (General - any meeting NOT related to the current project(s))',
  },
  {
    shortName: 'MeetingProject',
    longName:
      'Meeting (Project - any meeting client or internal related to the current project(s))',
  },
  {
    shortName: 'NonBillable',
    longName:
      'Non-billable (Idle time if there are no assigned project tasks also not worked on any other relevant tasks like learning etc.)',
  },
  {
    shortName: 'ProductManagement',
    longName: 'Product Management',
  },
  {
    shortName: 'ProductMarketing',
    longName: 'Product Marketing',
  },
  {
    shortName: 'ProductProjectLearning',
    longName:
      'Product/Project Learning (learning a new feature or learning the product/project when newly onboarded)',
  },
  {
    shortName: 'ProjectManagement',
    longName: 'Project Management',
  },
  {
    shortName: 'QATesting',
    longName: 'QA - Testing',
  },
  {
    shortName: 'RandD',
    longName: 'R & D',
  },
  {
    shortName: 'RecruitingFollowup',
    longName: 'Recruiting / Followup',
  },
  {
    shortName: 'Sales',
    longName: 'Sales',
  },
  {
    shortName: 'SocialMedia',
    longName: 'Social Media',
  },
  {
    shortName: 'Support',
    longName: 'Support',
  },
  {
    shortName: 'TechHelp',
    longName: 'Tech Help',
  },
];

export const trackEmployementStatus = () => {
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const hasPermission = permissions?.includes(
    PERMISSIONS.USER_PERMISSIONS.REQUEST_TIMESHEET_APPROVAL
  );
  const status = localStorage?.getItem('employmentStatus');
  // return status === 'Contract' || status === 'Freelancer' || hasPermission;
  return hasPermission;
};
