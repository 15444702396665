// SortIcon.js
import React from 'react';
import { IoArrowUp, IoArrowDown } from 'react-icons/io5';

const SortIcon = ({ sortOrder, onClick }) => {
  const isAscending = sortOrder === 'asc';

  return (
    <span onClick={onClick} className="cursor-pointer ml-2 text-gray-500 hover:text-gray-700">
      {isAscending ? <IoArrowUp /> : <IoArrowDown />}
    </span>
  );
};

export default SortIcon;
