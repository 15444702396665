import React from "react";
import TimesheetForm from "./TimesheetSection/TimesheetForm";

function UserTimesheetPage() {
  return (
    <div>
      <TimesheetForm />
    </div>
  );
}

export default UserTimesheetPage;
