import React from "react";

function OfflineComponent() {
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="flex justify-center items-center flex-col">
        <img
          src={require("../../assets/offline.jpg")}
          alt=""
          srcset=""
          className="py-2"
        />
        <p className="text-red-500 text-2xl">
          Oops! It seems your internet connection is unstable. Please check your
          connection.
        </p>
      </div>
    </div>
  );
}

export default OfflineComponent;
