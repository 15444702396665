import React, { useEffect, useState } from "react";
import ProjectList from "./ProjectSection/ProjectList";
import { IoAdd } from "react-icons/io5";
import CreateProjectForm from "./ProjectSection/ProjectForm";
import axios, { HttpStatusCode } from "axios";
import { BASE_URL } from "../Constants";
import { PERMISSIONS } from "./PermissionsConstant";
import ProgressLoader from "./ProgressLoader/ProgressLoader";

function ProjectPage() {
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/project/projects`); // Replace with your actual API endpoint
      setProjects(response.data);
      if (response.status === 200) {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);
  return (
    <div className="overfloy-y-scroll h-[100vh]">
      {isLoading ? <ProgressLoader /> : <ProjectList projects={projects} />}
      {permissions?.includes(
        PERMISSIONS.PROJECT_PERMISSIONS.CREATE_NEW_PROJECT
      ) && (
        <button
          onClick={() => setShowForm(true)}
          className="text-white font-semibold w-[3rem] h-[3rem] absolute right-20 bottom-20 bg-red-500 rounded-2xl flex justify-center items-center text-2xl"
        >
          <IoAdd />
        </button>
      )}
      {showForm && (
        <div
          className="fixed w-full h-screen bg-gray-800/80  left-0 right-0 top-0 flex justify-center items-center shadow-inner 
        bottom-0"
        >
          <div className="bg-white w-1/2 rounded-xl p-8">
            <CreateProjectForm
              handleClose={setShowForm}
              refetch={fetchProjects}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ProjectPage;
