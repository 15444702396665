import React, { useState, useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BASE_URL } from "../../Constants";
import { IoClose } from "react-icons/io5";
import { fetchGroupedApprovalRequests } from "../../api/NetworkService";

export const ShowTimesheetApprovalPopup = ({ hidePopup }) => {
  const [reportingManagers, setReportingManagers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [selectedRmId, setSelectedRmId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedGroups, setExpandedGroups] = useState({});
  const [showApproval, setShowApprovalUI] = useState(true);
  const [groupedRequests, setGroupedRequest] = useState([]);
  const userId = localStorage.getItem("uid");

  const toggleGroupExpansion = (groupKey) => {
    setExpandedGroups((prevExpanded) => ({
      ...prevExpanded,
      [groupKey]: !prevExpanded[groupKey], // Toggle expansion for this group
    }));
  };

  const [projects, setProjects] = useState([]);

  const [payload, setPayload] = useState({});

  const fetchProjects = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/getProjectByUserId/${localStorage.getItem("uid")}`
      );
      setProjects(response.data?.projects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);
  useEffect(() => {
    if (!showApproval) {
      fetchGroupedApprovalRequests(userId).then((groupedRequests) => {
        if (groupedRequests) {
          console.log("Grouped Data:", groupedRequests);
          setGroupedRequest(groupedRequests);
          // You can now pass this data to your component for rendering
        }
      });
    }
  }, [showApproval]);

  const handleRequestPayout = async (requests) => {
    // Add your logic for requesting payout
    const requestIds = requests.map((req) => req._id);
    const { startDate, endDate } = requests[0]; // Assuming all requests in the group have the same startDate and endDate

    try {
      const response = await axios.post(
        `${BASE_URL}/api/approval/sendRequestForPayoutApproval`,
        {
          requestIds,
          userId,
          startDate,
          endDate,
        }
      );

      if (response.status === 200) {
        alert("Payout request submitted successfully!");
        // Optionally, you can refresh the data or update state to reflect the changes
      } else {
        alert("Failed to submit payout request: " + response.data.message);
      }
    } catch (error) {
      console.error("Error making payout request:", error);
      alert(
        "An error occurred while submitting the payout request. Please try again."
      );
    }
  };

  const fetchProjectData = async (projectId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/user/user/getProjectDataFromRm/${projectId}`
      );
      setReportingManagers(response.data);
    } catch (err) {
      console.error("Error fetching project data:", err);
      setError("Failed to fetch project information.");
    }
  };

  const handleSubmit = async () => {
    console.log({ startDate, endDate, selectedProjectId });
    if (!startDate || !endDate || !selectedProjectId || !selectedRmId) {
      setError("Please select both dates and ensure project info is loaded.");
      return;
    }

    const submissionData = {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      projectId: selectedProjectId,
      rmUserId: selectedRmId,
      userId: localStorage.getItem("uid"),
    };

    setIsLoading(true);
    try {
      const response = await axios.post(
        `${BASE_URL}/api/approval/approveRequest`,
        submissionData
      );
      if (response.status === 201) {
        alert("Timesheet approval submitted successfully!");
        hidePopup(false);
      }
    } catch (err) {
      console.error("Error submitting approval:", err);
      setError("Failed to submit timesheet approval.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedProjectId) {
      fetchProjectData(selectedProjectId);
    }
  }, [selectedProjectId]);

  const handleProjectChange = (e) => {
    setSelectedProjectId(e.target.value);
    setPayload({
      ...payload,
      projectId: e.target.value,
    });
  };
  return (
    <div className="flex justify-center items-center bg-gray-800/70 w-screen z-50 h-screen fixed left-0 top-0 right-0 bottom-0">
      <div className="w-1/2 mx-auto bg-white p-8 mt-10 rounded-lg shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px] ">
        <div className="flex gap-4  mb-4 justify-between">
          <div>
            <button
              className={`outline-none rounded-sm border-t-0 border-l-0 border-r-0 ${
                showApproval ? "border-b-blue-600" : "border-b-gray-200"
              } border-[2px] py-2 px-4`}
              onClick={() => setShowApprovalUI(true)}
            >
              Request Approval
            </button>
            <button
              className={`outline-none rounded-sm border-t-0 border-l-0 border-r-0 ${
                !showApproval ? "border-b-blue-600" : "border-b-gray-200"
              } border-[2px] py-2 px-4`}
              onClick={() => setShowApprovalUI(false)}
            >
              Payout Request
            </button>
          </div>
          <button onClick={() => hidePopup(false)}>
            <IoClose className="cursor-pointer text-xl" />
          </button>
        </div>
        {showApproval ? (
          <div className="w-full">
            <div className="flex justify-between">
              <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
                Timesheet Approval
              </h2>
            </div>

            {error && <p className="text-red-600 text-center mb-4">{error}</p>}
            <div className="mb-4">
              <label className="block text-gray-700 font-semibold mb-2">
                Project Name <span className="text-red-600">*</span>
              </label>
              <select
                className="w-full border border-gray-300 rounded-lg mt-2 p-2 px-4 py-2 "
                value={payload.projectId}
                onChange={handleProjectChange}
              >
                <option value="">Select Project</option>
                {projects.map((project) => (
                  <option key={project.projectId} value={project.projectId}>
                    {project.projectName}
                  </option>
                ))}
              </select>
              {/* {errors[index]?.field_proj && (
              <p className="text-red-500">{errors[index].field_proj}</p>
            )} */}
            </div>

            {reportingManagers?.length > 0 ? (
              <div className="mb-4">
                <label className="block text-gray-700 font-semibold mb-2">
                  Reporting Manager:
                </label>
                <select
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-indigo-200 focus:border-indigo-300"
                  value={selectedRmId} // Bind the selected value
                  onChange={(e) => {
                    console.log("Selected Data", e.target.value);
                    setSelectedRmId(e.target.value); // Set the selected value
                  }}
                >
                  <option value="">Select a Reporting Manager</option>
                  {reportingManagers.map((rm) => (
                    <option key={rm._id} value={rm?._id}>
                      {rm?.fname} ({rm?.email})
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              selectedProjectId !== "" && (
                <p className="p-5 text-center text-red-500">
                  No is reporting manager associated with selected project,
                  Contact admin
                </p>
              )
            )}

            {reportingManagers?.length > 0 && selectedProjectId !== "" && (
              <div className="flex   gap-4">
                <div className="mb-4 ">
                  <label className="block text-gray-700 font-semibold mb-2">
                    Start Date:
                  </label>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="w-full px-4 py-2 border border-gray-300  rounded-lg focus:ring focus:ring-indigo-200 focus:border-indigo-300"
                    placeholderText="Select Start Date"
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700 font-semibold mb-2">
                    End Date:
                  </label>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring focus:ring-indigo-200 focus:border-indigo-300"
                    placeholderText="Select End Date"
                  />
                </div>
              </div>
            )}

            <div className="flex justify-center mt-6">
              <button
                onClick={handleSubmit}
                disabled={isLoading || reportingManagers?.length === 0}
                className={`w-full bg-indigo-600 text-white font-semibold py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300 ${
                  isLoading && "opacity-50 cursor-not-allowed"
                } ${
                  reportingManagers?.length === 0 &&
                  "bg-gray-700 hover:bg-gray-700 cursor-not-allowed"
                }`}
              >
                {isLoading ? "Submitting..." : "Submit Approval"}
              </button>
            </div>
          </div>
        ) : (
          <div className="w-full">
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
              Request Status
            </h2>
            <div className="p-6 bg-gray-100 ">
              <h2 className="text-2xl font-bold text-gray-700 mb-6">
                Approval Requests
              </h2>
              {Object.keys(groupedRequests).length === 0 ? (
                <p className="text-gray-500">No approval requests found.</p>
              ) : (
                Object.keys(groupedRequests).map((groupKey) => {
                  const requests = groupedRequests[groupKey];
                  const allApproved = requests.every(
                    (req) => req.isApproved === true
                  );
                  const isExpanded = expandedGroups[groupKey]; // Check if the group is expanded

                  return (
                    <div
                      key={groupKey}
                      className="bg-white shadow-lg rounded-lg mb-6 p-4"
                    >
                      <div className="flex justify-between items-center mb-4">
                        <h3
                          className="text-xl font-semibold text-gray-800 cursor-pointer"
                          onClick={() => toggleGroupExpansion(groupKey)} // Toggle expansion
                        >
                          {groupKey} {isExpanded ? "▲" : "▼"}
                        </h3>
                        <button
                          className={`px-4 py-2 rounded-md text-white ${
                            allApproved
                              ? "bg-green-500 hover:bg-green-600"
                              : "bg-gray-400 cursor-not-allowed"
                          }`}
                          disabled={!allApproved}
                          onClick={() => handleRequestPayout(requests)}
                        >
                          Request Payout
                        </button>
                      </div>

                      {isExpanded && ( // Render table only if the group is expanded
                        <table className="w-full text-left table-auto">
                          <thead>
                            <tr className="bg-gray-200">
                              <th className="px-4 py-2">Project</th>
                              <th className="px-4 py-2">Start Date</th>
                              <th className="px-4 py-2">End Date</th>
                              <th className="px-4 py-2">RM User</th>
                              <th className="px-4 py-2">Is Approved</th>
                            </tr>
                          </thead>
                          <tbody>
                            {requests.map((req) => (
                              <tr
                                key={req._id}
                                className="border-b hover:bg-gray-50"
                              >
                                <td className="px-4 py-2">
                                  {req.projectId.projectName}
                                </td>
                                <td className="px-4 py-2">
                                  {new Date(req.startDate).toLocaleDateString()}
                                </td>
                                <td className="px-4 py-2">
                                  {new Date(req.endDate).toLocaleDateString()}
                                </td>
                                <td className="px-4 py-2">
                                  {req.rmUserId.fname}
                                </td>
                                <td className="px-4 py-2">
                                  {req.isApproved ? (
                                    <span className="text-green-600 font-bold">
                                      Approved
                                    </span>
                                  ) : (
                                    <span className="text-red-600 font-bold">
                                      Pending
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  );
                })
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
