import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext/AuthContext"; // Import the useAuth hook
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import GoogleSignin from "../../auth/GoogleSignin";
import { loginUser } from "../../api/NetworkService";

const LoginComponent = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Loading state

  const navigate = useNavigate();
  const { login, setIsAdmin, setIsProjectManager, setIsOtherUser } = useAuth(); // Get the login function from context

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(""); // Clear error
    setLoading(true); // Start loading

    try {
      const userData = await loginUser(email, password); // Call modularized login function

      login(userData); // Update auth context

      const { roles, appRoles } = userData;

      if (appRoles.includes("Project Manager")) {
        setIsProjectManager(true);
      } else if (appRoles.includes("Admin")) {
        setIsAdmin(true);
      } else {
        setIsOtherUser(true);
      }

      const redirectLink = localStorage.getItem("redirectAfterLogin");
      console.log("Redirect Link", redirectLink);
      if (redirectLink) {
        // Redirect to the stored link
        window.location.href = redirectLink;
        localStorage.removeItem("redirectAfterLogin"); // Clear the stored link
        // return;
      }

      // Redirect based on role
      if (roles.includes("ADMIN")) {
        navigate("/admin");
      } else {
        navigate("/user");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="relative min-h-screen flex items-center justify-center bg-gray-100">
      {/* Conditionally show ProgressLoader based on loading state */}
      {loading && <ProgressLoader />}

      {/* Background image */}
      <div className="w-1/2 bg-[#00AAFF] h-[100vh] flex justify-center items-center shadow-xl">
        <img
          src={require("../../assets/water_mark_logo.png")}
          alt="WatermarkLogo"
        />
      </div>

      {/* Login Form */}
      <div className="w-1/2 flex justify-center items-center">
        <div className="relative bg-white p-6 md:p-8 rounded-lg shadow-lg w-full max-w-md mx-4 sm:mx-8 lg:mx-0 z-10">
          <h2 className="text-2xl font-bold text-center mb-6">Login</h2>

          {/* Show error message if it exists */}
          {error && <p className="text-red-500 mb-4 text-center">{error}</p>}

          <form onSubmit={handleSubmit} className="space-y-6">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="you@example.com"
              />
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="••••••••"
              />
            </div>

            <div className="flex justify-between items-center">
              <div className="flex items-center"></div>

              <div>
                <Link
                  to="/reset"
                  className="text-sm text-blue-600 hover:text-blue-500"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <button
              type="submit"
              className={`w-full outline outline-1 text-[#00AAFF] hover:bg-[#00AAFF] hover:text-white py-2 px-4 rounded-md hover:scale-[102%] transition ease-in-out delay-100 hover:-translate-y-1 duration-150 ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading} // Disable button while loading
            >
              {loading ? "Signing In..." : "Sign In"}
            </button>
          </form>

          {/* <p className="text-center text-gray-600 mt-6">
            <a
              href="#"
              className="text-[#00AAFF] flex items-center justify-center"
            >
              <FcGoogle className="mr-2 text-3xl" />
              Sign in with Google
            </a>
          </p> */}

          <GoogleSignin />
        </div>
      </div>
    </div>
  );
};

export default LoginComponent;
