import React, { useEffect } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { FaClock, FaHome, FaRegUser, FaTasks } from "react-icons/fa";
import { IoAddCircle, IoExit, IoFlagSharp } from "react-icons/io5";
import { SiFsecure,SiSwagger } from "react-icons/si";
import { GrProjects } from "react-icons/gr";
import { useAuth } from "./AuthContext/AuthContext";
import { FcFeedback } from "react-icons/fc";

function AdminDashboard() {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleLogout = () => {
    if (window.confirm("Are you sure you want to logout?")) {
      logout();
      navigate("/login"); // Redirect after logout
    }
  };

  return (
    <div className="flex w-full h-screen overflow-hidden">
      <div className="flex flex-col md:flex-row bg-[#213251] shadow-lg">
        <div className="w-full md:w-20 h-auto flex flex-col items-center p-4">
          <p className="text-lg font-extrabold text-white mb-10 mt-5">ADMIN</p>
          <ul className="flex flex-col space-y-2">
            {[
              { to: "/admin/home", icon: <FaHome />, tooltip: "Home" },
              { to: "/admin/users", icon: <FaRegUser />, tooltip: "Users" },
              {
                to: "/admin/create-timesheet",
                icon: <IoAddCircle />,
                tooltip: "Add Timesheet Entry",
              },
              {
                to: "/admin/projects",
                icon: <GrProjects />,
                tooltip: "Projects",
              },
              { to: "/admin/task", icon: <FaTasks />, tooltip: "Tasks" },
              {
                to: "/admin/timesheet",
                icon: <FaClock />,
                tooltip: "Timesheet",
              },

              {
                to: "/admin/permission",
                icon: <SiFsecure />,
                tooltip: "Permissions",
              },
              {
                to: "/admin/api-docs",
                icon: <SiSwagger />,
                tooltip: "Swagger",
              },
              {
                to: "/admin/flaggedEntries",
                icon: <IoFlagSharp />,
                tooltip: "Flagged Entries",
              },
              {
                to: "https://docs.google.com/forms/d/1BCJM3m8P0GlHPSEWkS6HV-GmXbRTi0B8eQ-oF2u7pyw/edit",
                icon: <FcFeedback />,
                tooltip: "Feedback form",
              },
            ].map((link) => (
              <div className="relative group" key={link.to}>
                {link.tooltip === "Feedback form" ? (
                  <Link
                    className="text-base
                  cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-10 h-10 flex items-center justify-center text-white"
                    to={link.to}
                    target="_"
                  >
                    {link.icon}
                  </Link>
                ) : (
                  <Link
                    className="text-base
                  cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-10 h-10 flex items-center justify-center text-white"
                    to={link.to}
                  >
                    {link.icon}
                  </Link>
                )}

                <div className="absolute text-xs bottom-full font-medium  left-10 px-3  py-2 text-center text-white bg-black/90 rounded hidden group-hover:block">
                  {link.tooltip}
                </div>
              </div>
            ))}
            <div className="relative group">
              <Link
                onClick={handleLogout}
                className="text-xl cursor-pointer hover:bg-blue-800 hover:rounded-full p-3 w-10 h-10 flex items-center justify-center text-white"
                to="#"
              >
                <IoExit />
              </Link>
              <div className="absolute text-xs bottom-full font-medium  left-10 px-3  py-2 text-center text-white bg-black/90 rounded hidden group-hover:block">
                Logout
              </div>
            </div>
          </ul>
        </div>
      </div>
      <div
        className="flex-1 p-4 md:p-12 overflow-scroll"
        id="scrollable-container"
      >
        <Outlet />
      </div>
    </div>
  );
}

export default AdminDashboard;
