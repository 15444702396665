import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import { sendPasswordResetLink } from "../../api/NetworkService";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      setError("Please enter your email address.");
      return;
    }

    setIsLoading(true);
    try {
      const response = await sendPasswordResetLink(email);
      if (response.status === 200) {
        setIsLoading(false);
        setSuccess("Password reset link sent to your email.");
        setError("");
        setTimeout(() => navigate("/login"), 5000); // Redirect to login after 5 seconds
      } else {
        setError("Failed to send password reset link. Please try again.");
        setSuccess("");
      }
    } catch (error) {
      setIsLoading(false);
      setError("An error occurred. Please try again later.");
      setSuccess("");
    }
  };

  return !isLoading ? (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4 text-center">Forgot Password</h2>
        <form onSubmit={handleSubmit} className="space-y-6">
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {success && <p className="text-green-500 mb-4">{success}</p>}

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email Address
            </label>
            <input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full mt-1 p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
              placeholder="you@example.com"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
          >
            Send Reset Link
          </button>
        </form>
      </div>
    </div>
  ) : (
    <ProgressLoader />
  );
};

export default ForgotPassword;
