import axios from "axios";
import React, { useEffect, useState } from "react";
import { BASE_URL } from "../../Constants";
import ProgressLoader from "../ProgressLoader/ProgressLoader";

function UserNotification() {
  const [notifications, setNotifications] = useState([]);
  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${BASE_URL}/api/notification/notifications/${localStorage.getItem(
          "uid"
        )}`
      );
      setNotifications(response.data);
    } catch (error) {
      if (error.response.status === 404) {
        setNotifications([]);
      } else {
        setError("Failed to fetch notifications");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handleMarkAsRead = async (notificationId) => {
    try {
      await axios.put(`${BASE_URL}/api/notification/read/${notificationId}`);
      setNotifications(
        notifications.map((notification) =>
          notification._id === notificationId
            ? { ...notification, status: "read" }
            : notification
        )
      );
    } catch (error) {
      console.error("Error marking notification as read", error);
    }
  };

  const filteredNotifications = notifications.filter((notification) => {
    if (filter === "all") return true;
    return notification.status === filter;
  });

  return (
    <div className="p-8 max-w-lg mx-auto">
      <h1 className="text-2xl font-semibold mb-4">User Notifications</h1>

      <div className="mb-6">
        <label className="block mb-2 text-sm font-medium text-gray-700">
          Filter
        </label>
        <select
          className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500"
          value={filter}
          onChange={handleFilterChange}
        >
          <option value="all">All</option>
          <option value="unread">Unread</option>
          <option value="read">Read</option>
        </select>
      </div>

      {loading ? (
        <ProgressLoader />
      ) : error ? (
        <p className="text-red-500">{error}</p>
      ) : (
        <ul>
          {filteredNotifications.length > 0 ? (
            filteredNotifications.map((notification) => (
              <li
                key={notification._id}
                className="mb-4 p-4 border border-gray-200 rounded-md bg-white shadow-sm"
              >
                <h4 className="text-lg font-medium">{notification.title}</h4>
                <p className="text-sm text-gray-600">{notification.message}</p>
                <small className="text-xs text-gray-400">
                  {new Date(notification.createdAt).toLocaleString()}
                </small>
                {notification.status === "unread" && (
                  <button
                    className="ms-10 mt-2 px-2 py-1 bg-indigo-600 text-white text-xs rounded-md hover:bg-indigo-700 focus:outline-none"
                    onClick={() => handleMarkAsRead(notification._id)}
                  >
                    Mark as Read
                  </button>
                )}
              </li>
            ))
          ) : (
            <div className="flex flex-col justify-center items-center">
              <img
                src={require("../../assets/notification-bell-icon-flat-vector-illustration-advertising-announcement-new-message-concept_668430-55.avif")}
                alt=""
                srcset=""
              />
              <p>No notifications available.</p>
            </div>
          )}
        </ul>
      )}
    </div>
  );
}

export default UserNotification;
