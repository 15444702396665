import React, { useState, useEffect } from "react";
import axios, { HttpStatusCode } from "axios";
import { IoClose } from "react-icons/io5";
import { BASE_URL } from "../../Constants";
import { PERMISSIONS } from "../PermissionsConstant";
import PermissionDenied from "../Permission/PermissionDenied";
import ProgressLoader from "../ProgressLoader/ProgressLoader";
import { getConversations } from "../../api/NetworkService";

const FlaggedTimesheetComponent = () => {
  const [timesheets, setTimesheets] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [project, setProject] = useState("");
  const [user, setUser] = useState("");
  const [projects, setProjects] = useState([]);
  const [showCommentUI, setShowCommentUI] = useState(false);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTimesheet, setSelectedTimesheet] = useState([]);
  const [query, setQuery] = useState([]);

  // export const getConversations = async (id) => {
  //   setIsLoading(true);
  //   try {
  //     const response = await axios.get(
  //       `${BASE_URL}/api/timesheet/getAllConversations/${id}`
  //     );
  //     console.log("Response", response?.data.data);
  //     setSelectedTimesheet(response?.data.data);

  //     if (response.status === HttpStatusCode.Ok) {
  //       setIsLoading(false);
  //     }
  //   } catch (error) {
  //     setIsLoading(false);
  //     console.log("Error", error);
  //     alert("Something went wrong");
  //   }
  // };
  // Set default date range to the last 7 days
  useEffect(() => {
    const today = new Date();
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);

    // Format dates as YYYY-MM-DD
    setStartDate(oneWeekAgo.toISOString().split("T")[0]);
    setEndDate(today.toISOString().split("T")[0]);
  }, []);

  // Fetch available projects
  const fetchProjects = async () => {
    setIsLoading(true);

    try {
      const response = await axios.get(`${BASE_URL}/api/project/projects`);
      setProjects(response.data);
      if (response.status === HttpStatusCode.Ok) {
        setIsLoading(false);
      }
    } catch (err) {
      console.error("Error fetching projects:", err);
      setIsLoading(false);
    }
  };

  // Fetch available users
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/api/user/getUsers`);
      setUsers(response.data);
      if (response.status === HttpStatusCode.Ok) {
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);

      console.error("Error fetching users:", err);
      alert("Something went wrong");
    }
  };

  // Fetch flagged timesheets based on filters
  const fetchFlaggedTimesheets = async () => {
    if (!startDate || !endDate) {
      setError("Please select both start and end dates.");
      return;
    }

    try {
      const formattedStartDate = new Date(startDate)
        .toISOString()
        .split("T")[0];
      const formattedEndDate = new Date(endDate).toISOString().split("T")[0];
      setIsLoading(true);
      const response = await axios.get(
        `${BASE_URL}/api/timesheet/getFlaggedTimesheet`,
        {
          params: {
            startDate: formattedStartDate,
            endDate: formattedEndDate,
            project,
            user,
          },
        }
      );
      if (response.status === HttpStatusCode.Ok) {
        setIsLoading(false);
      }
      setTimesheets(response.data);
      setError("");
    } catch (err) {
      setTimesheets([]);
      setIsLoading(false);
      console.error("Error fetching flagged timesheets:", err);
      setError("Failed to fetch flagged timesheets.");
    }
  };
  useEffect(() => {
    fetchProjects();
    fetchUsers();
  }, []);

  const flagTimesheet = async (_id, status, comment) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${BASE_URL}/api/timesheet/flagTimeSheet`,
        { _id, status, comment }
      );
      if (response.status === HttpStatusCode.Ok) {
        setIsLoading(false);
        // getConversations(_id);
        getConversations(_id, setIsLoading, setSelectedTimesheet);
        setQuery("");
      }
    } catch (error) {
      setIsLoading(false);
      alert("Something went wrong");
      console.error("Error flagging timesheet:", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetchFlaggedTimesheets();
  };
  const permissions = JSON.parse(localStorage.getItem("permissions"));

  return isLoading ? (
    <ProgressLoader />
  ) : permissions.includes(
      PERMISSIONS.TIMESHEET_PERMISSIONS.APPROVE_REJECT_TIMESHEET
    ) ? (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-4 font-montserrat">
        Flagged Timesheets
      </h1>

      {/* Error Message */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Filter Form */}
      <form
        onSubmit={handleSubmit}
        className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-4"
      >
        <div>
          <label className="block font-medium mb-2">
            Start Date <sup className="text-red-600">*</sup>
          </label>
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        <div>
          <label className="block font-medium mb-2">
            End Date <sup className="text-red-600">*</sup>
          </label>
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="w-full p-2 border rounded"
          />
        </div>
        {permissions.includes(
          PERMISSIONS.PROJECT_PERMISSIONS.VIEW_PROJECTS
        ) && (
          <div>
            <label className="block font-medium mb-2">Project</label>
            <select
              value={project}
              onChange={(e) => setProject(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">All Projects</option>
              {projects.map((proj) => (
                <option key={proj._id} value={proj._id}>
                  {proj.projectName}
                </option>
              ))}
            </select>
          </div>
        )}
        <div>
          <label className="block font-medium mb-2">User</label>
          <select
            value={user}
            onChange={(e) => setUser(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">All Users</option>
            {users.map((usr) => (
              <option key={usr?._id} value={usr?._id}>
                {usr?.name} {usr?.fname} {usr?.lname}
              </option>
            ))}
          </select>
        </div>
        <div className="col-span-4 flex justify-end">
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded"
          >
            Filter
          </button>
        </div>
      </form>

      {/* Timesheet List */}
      {timesheets.length > 0 ? (
        <div className="bg-white shadow-md p-4 rounded">
          <h2 className="text-xl font-semibold mb-2">Flagged Timesheets</h2>
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr className="bg-gray-100 border-b border-gray-300">
                <th className="py-2 px-4 text-left font-semibold text-gray-700">
                  User
                </th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">
                  Email
                </th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">
                  Project
                </th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">
                  Entry Date
                </th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">
                  Task
                </th>
                <th className="py-2 px-4 text-left font-semibold text-gray-700">
                  Time Spent
                </th>
              </tr>
            </thead>
            <tbody>
              {timesheets.map((timesheet) => (
                <tr
                  onClick={() => {
                    setShowCommentUI(true);
                    getConversations(timesheet?._id);
                  }}
                  key={timesheet._id}
                  className={`border-b cursor-pointer  ${
                    timesheet?.flagComment
                      ? "bg-yellow-100 border"
                      : "border-gray-200"
                  }`}
                >
                  <td className="py-2 px-4">
                    {timesheet?.uid?.fname} {timesheet?.uid?.lname}
                  </td>
                  <td className="py-2 px-4">{timesheet?.uid?.email}</td>
                  <td className="py-2 px-4">
                    {timesheet.field_proj?.projectName}
                  </td>
                  <td className="py-2 px-4">
                    {new Date(timesheet.field_entrydate).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 capitalize">
                    {timesheet.field_entrytask}
                  </td>
                  <td className="py-2 px-4">
                    {timesheet.field_time_spent} hours
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {showCommentUI && (
            <div className="bg-gray-800/80 fixed  left-0 top-0 right-0 bottom-0 flex justify-center items-center">
              <div className="bg-white w-[30rem] h-full  shadow-lg absolute right-0">
                <div className="flex justify-between p-8 flex-col">
                  <div className="flex justify-between mb-4">
                    <p className="font-bold text-[#333333] text-2xl">
                      Comment Logs
                    </p>
                    <button onClick={() => setShowCommentUI(false)}>
                      <IoClose />
                    </button>
                  </div>

                  <div className="space-y-4  flex h-[60vh] overflow-y-scroll flex-col ">
                    {selectedTimesheet?.map((comment) => (
                      <div
                        key={comment._id}
                        className="bg-white  shadow-md rounded-md p-2 border border-gray-200"
                      >
                        <div className="flex justify-between items-center cursor-pointer">
                          <h3 className="text-lg font-semibold text-gray-900">
                            {comment.query || "No query provided"}
                          </h3>
                        </div>
                        <div className="mt-1 space-y-1">
                          {comment.response ? (
                            <p className="text-gray-700">
                              <strong>Response:</strong> {comment.response}
                            </p>
                          ) : (
                            <p className="text-gray-500">
                              No response provided
                            </p>
                          )}
                          <p className="text-gray-500 text-sm">
                            <strong>Created At:</strong>{" "}
                            {new Date(comment.createdAt).toLocaleString()}
                          </p>
                          <p className="text-gray-500 text-sm">
                            <strong>Updated At:</strong>{" "}
                            {new Date(comment.updatedAt).toLocaleString()}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="absolute bottom-10 right-10 left-10">
                    <textarea
                      value={query}
                      onChange={(e) => setQuery(e.target.value)}
                      rows="4"
                      className="w-full p-2 border rounded mb-4"
                      placeholder="Enter your new query here..."
                    />
                    {error && <p className="text-red-500 mb-4">{error}</p>}
                    <button
                      onClick={() =>
                        flagTimesheet(
                          selectedTimesheet?.[0]?.timesheetUid,
                          true,
                          query
                        )
                      }
                      className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                      Raise New Query
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <img
            className="w-[15%]"
            src={require("../../assets/man-using-magnifying-glass-free-png.webp")}
            alt=""
            srcset=""
          />
          <p className="text-gray-500 mt-10">
            No flagged timesheets found for the selected range.
          </p>
        </div>
      )}
    </div>
  ) : (
    <PermissionDenied />
  );
};

export default FlaggedTimesheetComponent;
